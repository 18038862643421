@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

@layer components {
    .md-container {
        @apply flex col-span-2;
        @apply lg:col-span-4;
    }

    .l-container {
        @apply col-span-2;
        @apply md:col-span-4;
        @apply lg:col-span-6;
    }

    .nv-grid {
        @apply grid grid-cols-2 gap-4 mx-4;
        @apply md:grid-cols-4 md:mx-6;
        @apply lg:grid-cols-12 lg:gap-6 lg:mx-8;
        @apply xl:gap-8 xl:mx-10;
        @apply 2xl:max-w-screen-2xl 2xl:mx-auto;
    }

    .nv-thead {
        @apply bg-white top-[102px] z-10;
    }

    .nv-thead::after {
        content: '';
        @apply border-b w-full absolute border-b-gray-200;
    }

    .nv-filter:hover > svg {
        @apply transition duration-150 text-indigo-500;
    }
}
